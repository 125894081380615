import React from "react"
import {
  Container,
  Main,
  Cutin,
  Content,
  Contact,
  Iframely,
  Seo,
  FeatureImage,
  ContentCard,
  Button,
} from "../components"
import { FixedPageH1, FixedPageH2, P } from "../elements"
import { graphql, Link } from "gatsby"

const floatPage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="山車・御神輿"
        pagedesc="山車・御神輿の塗り・塗替えについてのページです。お祭り好き塗師による山車・御神輿の塗り情報ブログもご覧いただけます。"
        pagepath={location.pathname}
        pageimg={data.nakaroku01.childImageSharp.original.src}
        pageimgw={data.nakaroku01.childImageSharp.original.width}
        pageimgh={data.nakaroku01.childImageSharp.original.height}
      />
      <Iframely />
      <FeatureImage fixed={data.nakaroku01.childImageSharp.fixed} />
      <Cutin>
        <Main>
          <FixedPageH1>
            <h1>山車・御神輿</h1>
          </FixedPageH1>
          <Content>
            <FixedPageH2>山車塗りへの想い</FixedPageH2>
            <P>山車はお祭りの大切なシンボルです。</P>
            <P>
              山車小屋から出すたび「今年もいよいよお祭りが始まるなぁ」とワクワクしますよね。
            </P>
            <P>
              そんな山車の維持をするための積立資金は、町内から集まった大切な資金です。
            </P>
            <P>町内の大切な資金を無駄にしたくありません。</P>
            <P>
              当ページでは、山車・御神輿の塗りについて「良いものを安く」という視点で発信しています。
            </P>
            <P>大切な山車の作成・修復計画にお役立ていただけると幸いです。</P>
          </Content>
          <Content>
            <ContentCard
              fixed={data.sandaimeFixed.childImageSharp.fixed}
              title="マルスエ佛壇について"
              cover="About"
            >
              <div>
                <Button href="/profile/">どんなお店？ページへ</Button>
              </div>
            </ContentCard>
          </Content>
          <Content>
            <div id="service"></div>
            <FixedPageH2>サービス一覧</FixedPageH2>
            <ContentCard
              fixed={data.shokuninmachi.childImageSharp.fixed}
              title="山車の塗り・塗替え"
              cover="Urushi"
            >
              <div>
                <Button href="/marusue-dashi-urushi/">山車塗りページへ</Button>
              </div>
            </ContentCard>
          </Content>
          <Content>
            <div id="yondaime-blog"></div>
            <ContentCard
              fixed={data.omatsuriotoko.childImageSharp.fixed}
              title="お祭り塗師による漆塗り情報ブログ"
              cover="Blog"
            >
              <div id="001"></div>
              <ul>
                <li>
                  <Link to="/dashi-soushoku/">山車の装飾とは？</Link>
                </li>
                <li>
                  <Link to="/dashi-paint/">山車の塗り・塗替えについて</Link>
                </li>
                <li>
                  <Link to="/omikoshi-paint/">御神輿の塗り・塗替えについて</Link>
                </li>
                <li>
                  <Link to="/dashi-omikoshi/">山車と御神輿の違いとは？</Link>
                </li>
                <li>
                  <Link to="/dashi-taiko/">山車の太鼓とは？</Link>
                </li>
              </ul>
            </ContentCard>
          </Content>
          <Content>
            <FixedPageH2>お問い合わせ</FixedPageH2>
            <Contact />
            <div className="iframely-embed">
              <div
                className="iframely-responsive"
                style={{ height: 140, paddingBottom: 0 }}
              >
                <a
                  href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                  data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                  aria-label="GoogleMaps"
                >
                  {null}
                </a>
              </div>
            </div>
          </Content>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    nakaroku01: file(relativePath: { eq: "nakaroku01.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    shokuninmachi: file(relativePath: { eq: "shokuninmachi.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    nakaroku01: file(relativePath: { eq: "nakaroku01.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sandaimeFixed: file(relativePath: { eq: "sandaimeFixed.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    omatsuriotoko: file(relativePath: { eq: "omatsuriotoko.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default floatPage
